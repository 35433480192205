import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      warningThisContent:"WARNING THIS CONTENT IS AGE RESTRICTED",
      pleaseScan:"TO CONTINUE, PLEASE SCAN YOUR PASSPORT TO VERIFY YOUR AGE",
      scanNow:"SCAN NOW",
      privacyPolicy:"IQOS DOES NOT RETAIN YOUR DETAILS",
      policyLink:"Privacy Policy",
      cancelButton:"CANCEL",
      passportNotRecognised:"YOUR PASSPORT HAS NOT BEEN RECOGNISED PLEASE TRY AGAIN",
      pleaseScanPassportPhoto:"PLEASE SCAN YOUR PASSPORT PHOTO PAGE",
      photoTip:'tip: make sure details are clearly visible',
      verifyingYourAge:'VERIFYING YOUR AGE',
      mayTakeMoments:'THIS MAY TAKE A FEW MOMENTS',
      verificationDone0:"VERIFICATION",
      verificationDone1:"SUCCESSFUL",
      verificationDone2:"UNSUCCESSFUL",
      verificationDenied:"THIS WEBSITE CONTAINS CONTENT RELATED TO TOBACCO PRODUCTS AND IS NOT SUITABLE FOR MINORS.",
      captureCta:"CAPTURE",
      fallOpenBCamera:"OPEN CAMERA",
    }
  },

  ar: {
    translation: {
      
    }
  },

  de: {
    translation: {
      
    }
  },

  fr: {
    translation: {
      
    }
  },

  zh: {
    translation: {
      
    }
  },

  ja: {
    translation: {
      warningThisContent: "本サイトは、未成年者の喫煙防止並びに適正なたばこの販売、広告および販売促進活動の観点から、満20歳以上の喫煙者以外はご覧いただけないコンテンツを含んでいます。",
      pleaseScan: '本サイトは、未成年者の喫煙防止並びに適正なたばこの販売、広告および販売促進活動の観点から、満20歳以上の喫煙者であることを確認させていただきます。本サイトを進めるには、年齢確認の為ご本人様のパスポートをスキャンしてください。',
      scanNow:"スキャンする",
      privacyPolicy:"当社はお客様情報を保持しません",
      policyLink:"プライバシーポリシー",
      cancelButton:"キャンセルする",

      passportNotRecognised:"年齢認証中にエラーが発生しました。もう一度お試しください",
      pleaseScanPassportPhoto:"顔写真のページをスキャンしてください",
      photoTip:'顔写真のページを全て鮮明に映してください',
      verifyingYourAge:'年齢を確認する',
      mayTakeMoments:'年齢認証中',
      verificationDone0:"年齢認証完了",
      verificationDone1:"年齢認証完了",
      verificationDone2:"年齢認証に失敗しました",
      verificationDenied:"T本サイトは、未成年者の喫煙防止並びに適正なたばこの販売、広告および販売促進活動の観点から、満20歳以上の喫煙者以外はご覧いただけません。",
      captureCta:"キャプチャする",
      fallOpenBCamera:"オープンカメラ",
    }
  },

  ko: {
    translation: {
      
    }
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
})



export default i18n;