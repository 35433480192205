import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { Camera } from "react-camera-pro";
import { useTranslation } from 'react-i18next';

import {sendPassportFile, updatePassportProgress} from '../../services'
import { VerificationCameraProps } from './verification-camera.types'
import { useStyles } from './verification-camera.styles'
import { formatDateOfBirthFromMRZ, getBase64, isValidDate } from '~utils';

const VerificationCamera: React.FC<{ (VerificationCameraProps) }> = ({
  setCameraOn,
  browserVersion,
  doesContainGetUserMedia
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [age, setAge] = useState<number | null>(null)
  const [userImg, setUserImg] = useState<string | null>(null)
  const [data, setData] = useState<any | null>(null)
  const [authorized, setAuthorized] = useState<boolean | null>(null)
  const webcamRef = useRef(null);

  const sendImg = async (blob) => {
    const formData = new FormData()
    formData.append('file', blob)
    try {
      await updatePassportProgress({inProgress: true, type:'kiosk'})
      const { data } = await sendPassportFile(formData)
      setData(data)
    } catch (error) {
      setIsLoading(false)
      resetCamera()
    }
  }

  const verifyAge = (dateOfBirth) => {
    const date = moment(dateOfBirth).format('YYYY-MM-DD')
    const validDate = isValidDate(new Date(date))
    if (validDate === true) {
      const formatedDate = `${date}T00:00:00Z`
      const age = moment().diff(formatedDate, 'years');
      setAge(age)
      setIsLoading(false)
    } else {
      setError(true)
      resetCamera()
    }
  }

  const resetCamera = () => {
    setAuthorized(null)
    setIsLoading(false)
    setUserImg(null)
    setAge(null)
    setData(null)
  }

  if (data) {
    const mrzData = data[0]?.prediction?.find(data => data?.label === "MRZ")
    const testMrz = formatDateOfBirthFromMRZ(mrzData?.ocr_text)
    if (testMrz) {
      verifyAge(testMrz)
    } else {
      setError(true)
      resetCamera()
    }
    setData(null)
  }

  useEffect(() => {
    (async () => {
      if (age !== null) {
        if (age >= 21) {
          setAuthorized(true)
          await updatePassportProgress({completed: true, type:'kiosk'})
        } else {
          setAuthorized(false)
          await updatePassportProgress({failed: true, type:'kiosk'})
          setTimeout(() => {
            window.open("https://www.iqos.com", "_self")
          }, 10000)
        }
        setAge(null)
      }
    })();
  }, [age])


  const handleTakePhoto = (dataUri) => {
    setUserImg(dataUri)
    setIsLoading(true)
    const url = dataUri
    if (dataUri) {
      fetch(url)
        .then(res => res.blob())
        .then(blob => sendImg(blob))
    } else {
      sendImg(url)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setError(false)
    }, 4000)
  }, [error])

  return (
    <div className={classes.root}>
      <div className={classes.headingContainer} >
        <img
          className={classes.mainIqosLogo}
          src={require('../../images/iqos_logo.png')}
          alt="iqos"
        />
        <div className={classes.messageBox} >
          {error ? (
            <>
              <h3 className={classes.errorMessage} > {t('passportNotRecognised')} </h3>
              <p className={classes.errorTip} > {t('photoTip')} </p>
            </>
          ) : (
            <>
              <hr className={classes.hr} />
              <h3 className={classes.white}> {t('pleaseScanPassportPhoto')} </h3>
              <hr className={classes.hr} />
              <p className={classes.tip} > {t('photoTip')} </p>
            </>
          )}
        </div>
        {isLoading === true && (
          <div className={classes.loadingScreen}>
            <h3 className={classes.loadingText} >{t('verifyingYourAge')}</h3>
            <div className={classes.gifWrapperLoading} >
              <img className={classes.passportLoader} src={require("../../images/Loading.gif")} alt="iqos" />
            </div>
            <h3 className={classes.passportLoadingText} >
              {t('mayTakeMoments')}
            </h3>
          </div>
        )}
        {authorized === true &&
          <div className={classes.loadingScreen} >
            <h3 className={classes.passportLoadingText2} >
              {t('verificationDone0')}
            </h3>
            <div className={classes.gifWrapper} >
              <img className={classes.passportLoader} src={require("../../images/Tick.gif")} alt="iqos" />
            </div>
            <h3 className={classes.passportLoadingText2} >
              {t('verificationDone1')}
            </h3>
          </div>
        }
        {authorized === false &&
          <div className={classes.loadingScreen} >
            <h3 className={classes.passportLoadingText3} >
              {t('verificationDone0')}
            </h3>
            <h3 className={classes.passportLoadingText3} >
              {t('verificationDone2')}
            </h3>
            <div className={classes.gifWrapperNotVerified} >
              <img className={classes.passportLoader} src={require("../../images/Cross.gif")} alt="iqos" />
            </div>
            <p className={classes.restrictedMessage} >
              {t('verificationDenied')}
            </p>
          </div>
        }
        {error === true &&
          <div className={classes.errorScreen} >
            <div className={classes.gifWrapperError} >
              <img className={classes.errorIcon} src={require("../../images/Warning.gif")} alt="iqos" />
            </div>
          </div>
        }
      </div>
      {userImg ? (
        <div className={classes.screenshot}>
          <img
            className={classes.screenshotImg}
            src={userImg}
          />
        </div>
      ) : (
        <div className={classes.passportContainer}>
          <>
            <div className={classes.passportOutline} ></div>
            <div className={classes.cameraWrapper} >
              <Camera
                facingMode='environment'
                ref={webcamRef}
                aspectRatio={18 / 36}
                onLoad={() => console.log('loaded')}
              />
            </div>
          </>
        </div>
      )}
      <div className={classes.optionsContainer} >
        <div className={classes.buttonContainer} >
          {browserVersion.name !== "ANYTHING" ? (
            <button
              className={classes.captureButton}
              onClick={() => handleTakePhoto(webcamRef.current.takePhoto())}
            >
              {t('captureCta')}
            </button>
          ) : (
            <div className={classes.fallbackWrapper} >
              <div className={classes.fallbackContainer} >
                <div className={classes.fallBackText}>{t('fallOpenBCamera')}</div>
                <input
                  onChange={(event) =>
                    getBase64(event.target.files[0])
                      .then(data => handleTakePhoto(data)
                      )}
                  className={classes.fallbackScanButton}
                  type='file'
                  accept="image/*"
                  capture="environment"
                />
              </div>
            </div>
          )}
          <button
            className={browserVersion.name === 'Chrome' ? classes.fallbackCancel : classes.cancelButton}
            onClick={() => setCameraOn(false)}>
            {t('cancelButton')}
          </button>
        </div>
        <p className={classes.privacyPolicy} >
          {t('privacyPolicy')}
          <br />
          <span
            className={classes.policyLink}
            onClick={() =>
              window.open("https://www.pmiprivacy.com/en/consumer", "_blank")
            }>
            {t('policyLink')}
          </span>
        </p>
      </div>
    </div>
  )
}

export default VerificationCamera
