import axios from 'axios'
import queryString from 'query-string';

// const url = ;
const url = process.env.NODE_ENV === 'development' ? process.env.STAGING ? 'https://staging.260438f79400.com' : `http://localhost:3001` : 'https://v3.260438f79400.com';

interface PassportInputs {
    scannedQr?: boolean;
    cameraOn?: boolean;
    inProgress?: boolean;
    completed?: boolean;
    failed?: boolean;
    type?: 'kiosk' | 'mobile';
    dropOff?: boolean;
}

export const sendPassportFile = async (file: any) => {
    return axios.post(
        `${url}/passport`, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': "*",
            }
        }
    )
}

export const updatePassportProgress = async (body: PassportInputs) => {
    const params = queryString.parse(location.search.replace('?', ''));
    try {
        return axios.put(`${url}/passport/${params?.id}`, body)
    } catch (e) {
        console.log(e)
    }
}

