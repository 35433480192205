export const getBrowserVersion = () => {
  var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/)
    if (tem != null) { return { name: 'Opera', version: tem[1] }; }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
  return {
    name: M[0],
    version: M[1]
  };
}

export const checkDeviceForGetUserMedia = () => {
  if (window.navigator?.mediaDevices?.getUserMedia) {
    return true
  } else {
    return false
  }
}

export const formatDateOfBirthFromMRZ = (string) => {
  const dobMatch = string?.match(/[A-Z]{3}?[0-9]{6}?/g)

  if (dobMatch) {
    const mrzDob = dobMatch[0]

    //Test for country codes.
    const countryCode = `${mrzDob[0]}${mrzDob[1]}${mrzDob[2]}`

    let year = parseInt(mrzDob[3] + mrzDob[4])
    if (year > 40) {
      year += 1900
    } else {
      year += 2000
    }
    console.log(parseInt(mrzDob[3] + mrzDob[4]))
    const dateOfBirthString = `${year.toString()}-${mrzDob[5]}${mrzDob[6]}-${mrzDob[7]}${mrzDob[8]}`
    console.log(dateOfBirthString)
    return dateOfBirthString
  } else {
    false
  }
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}