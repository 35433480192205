import React from 'react'
import ReactDOM from 'react-dom'
import "regenerator-runtime/runtime"
import './normalize.css'
import "./i18n"
import LAGatewayMobile from './components/LAGatewayMobile/LAGatewayMobile'

ReactDOM.render(
  <LAGatewayMobile />,
  document.getElementById('root')
)