import React, { useState } from 'react'
import { useTranslation } from "react-i18next"

import {languageFlags} from './language-flags'
import { LanguageSelectorProps } from './language-selector.types'
import { useStyles } from './language-selector.styles'

const LanguageSelector: React.FC<{ (LanguageSelectorProps) }> = ({
  languages,
}) => {
  const { i18n } = useTranslation()
  const [languagesOpen, setLanguagesOpen] = useState<boolean>(false)
  const [activeLanguage, setActiveLanguage] = useState<{ name:string, image:string }>({
    name:'en',
    image: require('../../images/en-flag.png')
  })
  const classes = useStyles()

  const onLanguageSelect = (event) => {
    const eventLanguage = event.target.value
    i18n.changeLanguage(eventLanguage)
    const selectedLanguage = languageFlags.filter(language => language.name === eventLanguage)
    setActiveLanguage(...selectedLanguage)
    setLanguagesOpen(false)
  }

  const handleOpenLanguages = () => {
    setLanguagesOpen(!languagesOpen)
  }
  
  const filteredLanguages = languageFlags?.filter(flag => 
    languages?.includes(flag?.name) && flag?.name !== activeLanguage.name
  )

  return (
    <div className={classes.root} >
       <div 
        className={classes.activeLanguage} 
        key={activeLanguage.name} 
      >
        <img 
          className={classes.flag} 
          src={activeLanguage.image} 
        />
        <button
          className={classes.button}
          value={activeLanguage.name}
          onClick={handleOpenLanguages}
        />
      </div>
      {languagesOpen && (
      filteredLanguages.map(lang =>
        <div className={classes.languageButtonContainer} key={lang.name} >
          <img 
            className={classes.flag} 
            src={lang.image} 
          />
          <button
            className={classes.button}
            value={lang.name}
            onClick={onLanguageSelect}
          />
        </div>
        )
      )}
    </div>
  )
}

export default LanguageSelector