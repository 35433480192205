import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    root:{
      width:'9%',
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-start',
      position:'absolute',
      zIndex:99,
      right:0,
      top:0,
      padding:'3%',
      alignItems:'center',
    },
    langButton:{
        position:'absolute',
        outline:'none',
        boxShadow:'none',
        width:'10%'
    },
    flag:{
      width:'100%'
    },
    button:{
      position:'absolute',
      width:30,
      height:30,
      opacity:0
    },
    languageButtonContainer:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      height:'5%',
      width:'fit-content',
      marginBottom:'20%',
    },
    activeLanguage:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      height:'5%',
      width:'fit-content',
      marginBottom:'20%',
    }
}) 