import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    root:{
        width:'100vw',
        height:'90%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        alignItems:'center',
        position:'absolute',
        animationName:'$fade-in',
        animationDuration:'1.5s',
        animationFillMode:'forwards',
    },
    headingContainer:{
        zIndex:9,
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    mainIqosLogo:{
        width:'40%',
        zIndex:9,
    },
    messageBox:{
        padding:'0% 10% 0% 10%',
        zIndex:9,
        textAlign:'center',
    },
    loadingScreen:{
        width:'100vw',
        height:'105vh',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        backgroundColor:'rgba(0,0,0,0.8)',
        position:'absolute',
        zIndex:9,
        top:'-10%'
    },
    gifWrapper:{
        top:'0%',
        width:'25%',
        height:'12.5%',
        position:'relative',
    },
    gifWrapperLoading:{
        width:'25%',
        height:'12.5%',
    },
    passportLoader:{
        width:'100%',
        animationName:'fade-in',
        animationDuration:'0.5s',
        animationFillMode:'forwards',
        zIndex:9,
        position:'realtive',
        top:'10%'
    },
    passportLoadingText:{
        padding:'3% 10% 5% 10%',
        color:'white',
        margin:0,

    },
    passportLoadingText2:{
        padding:'0% 10% 0% 10%',
        top:'12%',
        color:'#07fc07'
    },
    passportLoadingText3:{
        position:'relative',
        margin: '0px',
        top:'1%',
        color:'red'
    },
    hr1:{
        width:'50%',
        marginTop:'5%'
    },
    hr2:{
        width:'50%',
    },
    restrictedMessage:{
        padding:' 0% 7% 0% 7%',
        color:'red',
        position:'relative',
    },
    errorScreen:{
        width:'100vw',
        height:'105vh',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        position:'absolute',
        top:'-10%',
        zIndex:9,
    },
    gifWrapperError:{
        width:'56%',
        position:'relative',
        top:'-1%'
    },
    gifWrapperNotVerified:{
        width:'30%',
        height:'15%',
        position:'relative',
        top:'1%'
    },
    errorIcon:{
        width:'100%',
        animationName:'fade-in',
        animationDuration:'0.5s',
        animationFillMode:'forwards',
        zIndex:9,
    },
    errorTip:{
        color:'#F19537',
        padding:'0% 10% 2% 10%',
    },
    errorMessage:{
        marginBottom:0,
        color:'#F19537',
    },
    screenshot:{
        width:'100vw',
        height:'105vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'absolute',
        top: '-10%'
    },
    screenshotImg:{
        width:'100vw',
        height:'105vh',
        top:'-10%',
        objectFit:'cover'
    },
    passportContainer:{
        width:'100vw',
        height:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        position:'absolute'
    },
    passportOutline:{
        width:'90%',
        height:'35%',
        border:'2px solid #07fc07',
        position:'absolute',
        bottom:'35%',
        zIndex:9,
    },
    buttonContainer:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'space-between',
        height:'100%'
    },
    captureButton:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'70%',
        width:'35%',
        border:'1px solid white',
        borderRadius:'50%',
        color:'white',
        textAlign:'center',
        background:'rgba(0,0,0,0)',
        outline:'none',
        boxShadow:'none',
        zIndex:8
    },
    fallbackWrapper:{
        border:'1px solid white',
        borderRadius:'100%',
        padding:'5%',
        margin:'5%',
        width:80,
        height:80,
        display:'flex',
        justifyContent:'center',
        position:'relative',
        bottom:'18%'
    },
    fallbackContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'relative'
    },
    fallBackText:{
        position:'absolute',
        color:'white',
        textAlign:'center'
    },
    fallbackScanButton:{
        with:'100px',
        height:'100%',
        opacity:0,
        position:'absolute',
        zIndex:9
    },
    cancelButton:{
        textDecoration:'underline',
        position:'relative',
        border:'none',
        color:'white',
        background:'rgba(0, 0, 0, 0)'
    },
    fallbackCancel:{
        textDecoration:'underline',
        bottom:'15%',
        position:'relative',
        border:'none',
        color:'white',
        background:'rgba(0, 0, 0, 0)'
    },
    privacyPolicy:{
        textAlign:'center',
        fontSize:'75%',
        color:'white',
        height:'35px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        zIndex:8
    },
    policyLink:{
        textDecoration:'underline'
    },
    optionsContainer:{
        width:'80%',
        height:'32%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-evenly'
    },
    hr:{
        color:'white'
    },
    tip:{
        marginTop:'0px',
        color:'white'
    },
    loadingText:{
        color:'white',
        margin:0,
    },
    cameraWrapper:{
        width:'100%',
        height:'100%',
        position:'fixed',
        bottom:'0%'
    },
    '@keyframes fade-in':{
        from: {opacity: 0},
        to: {opacity: 1}
    },
    white:{
        color:'white'
    }
})