export const languageFlags = [
    {
      name:'en',
      image: require('../../images/en-flag.png')
    },
    {
      name:'ja',
      image: require('../../images/ja-flag.png')
    },
    {
      name:'ko',
      image: require('../../images/ko-flag.png')
    },
    {
      name:'ar',
      image: require('../../images/ar-flag.png')
    },
    {
      name:'de',
      image:require('../../images/de-flag.svg')
    },
    {
      name:'fr',
      image:require('../../images/fr-flag.svg')
    },
    {
      name:'zh',
      image:require('../../images/zh-flag.png')
    }
  ]