import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    root:{
        width:'100vw',
        height:'100vh',
        display:'flex',
        alignItems:'center',
        background:'#161616',
        position:'relative'
    },
    '@media screen and (orientation:landscape)':{
        root:{
            transform:'rotate(-90deg)',
            transformOrigin:'left top',
        }
    }
})