import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    root:{
      width:'100%',
      height:'95%',
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-around',
      alignItems:'center',
      animationName:'$fade-in',
      animationDuration:'1.5s',
      animationFillMode:'forwards',
      opacity:0,
    },
    mainIqosLogo:{
        width:'50%',
        position:'relative',
    },
    subtitle:{
        width:'80%',
    },
    cta:{
        margin:0
    },
    subTitleTextJa:{
        padding:'0% 10% 0% 10%',
        marginTop:'2%',
        marginBottom:'2%',
        fontSize:'81%',
        color:'white',
        textAlign:'center'
    },
    subtitleText:{
        padding:'0% 10% 0% 10%',
        marginTop:'3%',
        marginBottom:'3%',
        fontSize:'100%',
        color:'white',
        textAlign:'center'
    },
    iqosPassportIcon:{
        width:'30%',
    },
    ctaHeading:{
        padding:'0% 15% 0% 15%',
        lineHeight:'1.2',
        color:'white',
        textAlign:'center'
    },
    scanButton:{
        width:'85px',
        height:'85px',
        color:'white',
        border: '1px solid white',
        borderRadius:150,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontFamily:'Gotham',
        textAlign:'center'
    },
    privacyPolicy:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        textAlign:'center',
        fontSize:'75%',
        position:'relative',
        color:'white',
        height:'5%',
    },
    policyLink:{
        textDecoration:'underline',
        color:'white',
    },

    '@keyframes fade-in':{
        from: {opacity: 0},
        to: {opacity: 1}
    }
})