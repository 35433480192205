import React, {useEffect, useState} from 'react'
import i18next from 'i18next';

import VerificationPrompt from '../VerificationPrompt/VerificationPrompt'
import VerificationCamera from '../VerificationCamera/VerificationCamera'
import LanguageSelector from '../LanguageSelector/LanguageSelector'

import { getBrowserVersion, checkDeviceForGetUserMedia } from '../../utils'
import { useStyles } from './la-gateway-mobile.styles'
import queryString from "querystring";
import {updatePassportProgress} from "~services";

const LAGatewayMobile: React.FC = () => {
  const classes = useStyles()
  const [cameraOn, setCameraOn] = useState<boolean>(false)
  const [isNotVerified, setIsNotVerified] = useState<boolean | null>(null)
  const doesContainGetUserMedia = checkDeviceForGetUserMedia()
  const browserVersion = getBrowserVersion()
  const language = i18next.language;

  useEffect(() => {
    (async () => {
      await updatePassportProgress({scannedQr: true})
    })();
  }, [])

  useEffect(() => {
    if (!cameraOn) return;
    (async () => {
      await updatePassportProgress({cameraOn: true})
    })();
  }, [cameraOn])

  return (
    <div className={classes.root}>
      <LanguageSelector languages={['en','ja']} />
        {cameraOn ? (
          <VerificationCamera
            setCameraOn={setCameraOn}
            doesContainGetUserMedia={doesContainGetUserMedia}
            browserVersion={browserVersion}
          />
        ) : (
          <VerificationPrompt
            language={language}
            setCameraOn={setCameraOn}
          />
        )}
    </div>
  )
}

export default LAGatewayMobile
