import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { VerificationPromptProps } from './verification-prompt.types'
import { useStyles } from './verification-prompt.styles'

const VerificationPrompt: React.FC<{ (VerificationPromptProps) }> = ({
  setCameraOn,
  language,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <img className={classes.mainIqosLogo} src={require("../../images/iqos_logo.png")} alt="iqos" />
      <div className={classes.subtitle} >
        <hr />
          <h3 className={classes.subtitleText} >
            {t("warningThisContent")}
          </h3>
        <hr />
      </div>
      <img className={classes.iqosPassportIcon} src={require("../../images/MRZ.svg")} alt="iqos" />
        <div className={classes.ctaHeading}>
          <h3 className={classes.cta} >{t("pleaseScan")}</h3>
        </div>
      <div
        onClick={() => setCameraOn(true)}
        className={classes.scanButton}
      >
        {t('scanNow')}
      </div>
      <p className={classes.privacyPolicy} >
        {t('privacyPolicy')}
        <br />
        <span
          className={classes.policyLink}
          onClick={() => window.open(
            "https://www.pmiprivacy.com/en/consumer", "_blank"
          )}>
          {t('policyLink')}
        </span>
      </p>
    </div >
  )
}

export default VerificationPrompt